import React, { Component } from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  /* margin-top: 0.5rem; */
  width: 100%;

  label {
    position: absolute;
    top: 0;
    margin-left: 1rem;
    margin-bottom: 1rem;
    transition: all 200ms ease-in-out;
    background: ${colors.black};
    color: ${colors.white};
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    border-radius: 0.5rem;
    opacity: 0;
    pointer-events: none;
    white-space: ${props => (props.thin ? "nowrap" : "normal")};
    &:after {
      position: absolute;
      bottom: -0.5rem;
      left: 35%;
      background: ${colors.black};
      content: "";
      height: 0.6rem;
      width: 1rem;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
  }
  select {
    text-align: center;
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 400;
    /* color: #444; */
    line-height: 1.3;
    padding: ${props => (props.thin ? "0.5rem" : "0.6em 1.4em 0.5em 0.8em")};
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid ${colors.mediumGrey};
    ${colors.boxShadowInput}
    border-radius: 0.5rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, ${colors.white} 0%, ${colors.white} 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    &:focus {
      border-color: ${colors.black};
      outline: none;
      + label {
        opacity: 1;
        top: ${props => (props.thin ? "-2.5rem" : "-2.5rem")};
      }
    }
  }
`

export default class Select extends Component {
  render() {
    const { props } = this

    return (
      <StyledSelectContainer thin={props.thin}>
        <select // eslint-disable-line
          onChange={props.onChange}
          className={props.className}
          name={props.name}
          id={props.id}
          value={props.value}
        >
          {props.children}
        </select>
        <label htmlFor={props.name}>{props.label}</label>
      </StyledSelectContainer>
    )
  }
}
