import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { updateCanvas } from "../utils/updateCanvas"
import Button from "../components/button"
import Axios from "axios"
import { graphql, useStaticQuery } from "gatsby"
import Select from "../components/select"
import Checkout from "../components/checkout"
import LoaderContainer from "../components/loaderContainer"
import { colors } from "../utils/siteVars"
import Narrow from "../components/narrow"

const StyledMakePage = styled.div`
  h1 {
    margin: 1rem 0 3rem 1rem;
    font-size: 3rem;
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    .image-container {
      position: relative;
    }
    .options-container {
      background: ${colors.dark};
      padding: 1rem;
      border-radius: 5px;
      form {
        div.form-div {
          margin-bottom: 2rem;
        }
        p.ul-style {
          font-weight: 300;
          letter-spacing: 0.1rem;
          font-size: 1.1rem;
          margin: 0;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      select,
      input {
        font-size: 1rem;
        padding: 1rem;
        font-family: inherit;
        border-radius: 5px;
        border: none;
      }
      select,
      button,
      input {
        text-align: center;
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
  .image-container {
    img {
      border-radius: 5px;
    }
  }
  @media (max-width: 768px) {
    .content {
      display: flex;
      flex-direction: column-reverse;
    }
  }
`

const MakePage = () => {
  const [previewImage, setPreviewImage] = useState(null)
  const [baseImage] = useState("/images/tshirt-Front-White.jpg")
  const [customImage, setCustomImage] = useState("/images/placeholder.png")
  const [shirtSize, setShirtSize] = useState("L")
  const [buyBtnAvailiable, setBuyBtnAvailiable] = useState(false)
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    adress: "",
    email: "",
    zipCode: "",
    country: "",
    imageUrl: "",
  })
  const [loading, setLoading] = useState(true)

  const readUpload = e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = () => {
      setCustomImage(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const initUpload = () => {
    const i = document.createElement("input")
    i.type = "file"
    i.click()
    i.onchange = e => {
      readUpload(e)
    }
  }

  useEffect(() => {
    const { firstName, lastName, adress, zipCode, email, country } = formData
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      adress.length > 0 &&
      zipCode.length > 0 &&
      email.length > 0 &&
      country.length > 0
    ) {
      setBuyBtnAvailiable(true)
    } else setBuyBtnAvailiable(false)
  }, [formData])

  const renderPreview = async () => {
    const previewSrc = await updateCanvas(baseImage, customImage)
    setPreviewImage(previewSrc)
  }

  const submit = e => {
    e.preventDefault()
    setLoading(true)
    console.log("Submitting...")
    if (customImage) {
      Axios.post("https://api.cloudinary.com/v1_1/katalysatorav/image/upload", {
        file: customImage,
        upload_preset: "rfrh6h5j",
      }).then(res => {
        setFormData({
          ...formData,
          imageUrl: res.data.url,
        })
        const btn = document.querySelector("#buyButton")
        btn.click()
      })
    } else {
      const btn = document.querySelector("#buyButton")
      btn.click()
    }
  }

  useEffect(() => {
    if (baseImage) renderPreview()
  }, [baseImage, customImage])

  const sizes = ["S", "M", "L", "XL", "XXL"]

  const query = useStaticQuery(graphql`
    query makeQuery {
      bgImg: file(relativePath: { eq: "bg_make.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const c = "rgba(30,50,100,0.3)"
  const layeredBG = [
    `linear-gradient(${c}, ${c})`,
    query.bgImg.childImageSharp.fluid,
  ]

  return (
    <Layout title="Design t-shirt" bg={layeredBG}>
      <LoaderContainer active={loading} />
      <Narrow>
        <StyledMakePage>
          <div className="headers">
            <h1>Create a custom t-shirt in 3 easy steps!</h1>
          </div>
          <div className="content">
            <div className="options-container">
              <form onSubmit={submit}>
                <p className="ul-style">
                  Upload an image and select your shirt size
                </p>
                <div className="form-div">
                  <Button
                    onClick={initUpload}
                    type="button"
                    bg={colors.secondary}
                  >
                    Upload image
                  </Button>
                  <Select
                    label="Size"
                    onChange={e => {
                      setShirtSize(e.target.value)
                    }}
                    value={shirtSize}
                  >
                    {sizes.map(size => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </Select>
                </div>
                <p className="ul-style">
                  Tell us where you want us to send the t-shirt
                </p>
                <div className="form-div">
                  <input
                    required
                    name="First name"
                    type="text"
                    value={formData.firstName}
                    onChange={e => {
                      setFormData({ ...formData, firstName: e.target.value })
                    }}
                    placeholder="First name"
                  />
                  <input
                    required
                    name="Last name"
                    type="text"
                    value={formData.lastName}
                    onChange={e => {
                      setFormData({ ...formData, lastName: e.target.value })
                    }}
                    placeholder="Last name"
                  />
                  <input
                    required
                    name="Email"
                    type="email"
                    value={formData.email}
                    onChange={e => {
                      setFormData({ ...formData, email: e.target.value })
                    }}
                    placeholder="Email"
                  />
                  <input
                    required
                    name="Adress"
                    type="text"
                    value={formData.adress}
                    onChange={e => {
                      setFormData({ ...formData, adress: e.target.value })
                    }}
                    placeholder="Adress"
                  />
                  <input
                    required
                    name="ZIP"
                    type="text"
                    value={formData.zipCode}
                    onChange={e => {
                      setFormData({ ...formData, zipCode: e.target.value })
                    }}
                    placeholder="ZIP code"
                  />
                  <input
                    required
                    name="Country"
                    type="text"
                    value={formData.country}
                    onChange={e => {
                      setFormData({ ...formData, country: e.target.value })
                    }}
                    placeholder="Country"
                  />
                </div>
                <p className="ul-style">Provide your payment info</p>
                <div className="">
                  <Button
                    type="submit"
                    bg={colors.primary}
                    color={colors.offWhite}
                    icon="/icons/lock.png"
                  >
                    Go to payment
                  </Button>
                </div>
                <Checkout
                  formData={formData}
                  availiable={buyBtnAvailiable}
                  id="buyButton"
                />
              </form>
            </div>
            <div className="image-container">
              <img
                src={previewImage}
                width="100%"
                alt=""
                onLoad={() => {
                  setLoading(false)
                }}
              />
            </div>
          </div>
        </StyledMakePage>
      </Narrow>
    </Layout>
  )
}

export default MakePage
