import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
import Axios from "axios"
import { cancelURL, db, successURL } from "../utils/siteVars"
const buttonStyles = {
  fontSize: "1rem",
  textAlign: "center",
  color: "#000",
  padding: "12px 60px",
  boxShadow: "2px 5px 10px rgba(0,0,0,.1)",
  backgroundColor: "rgb(255, 178, 56)",
  borderRadius: "6px",
  letterSpacing: "1.5px",
  width: "100%",
  marignTop: "1rem",
  display: "none",
}
const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
  display: "none",
}
let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_test_Ohx6Drs56n7YRGIs9y3kpbeG0054jy0v5v")
  }
  return stripePromise
}
const Checkout = props => {
  const [loading, setLoading] = useState(false)

  const redirectToCheckout = async event => {
    event.preventDefault()
    const queryString = Object.keys(props.formData)
      .map(key => `${key}=${props.formData[key]}`)
      .join("&")
    const encodedQueryString = encodeURI(queryString)
    setLoading(true)
    const stripe = await getStripe()
    stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: "price_1HYJt9L9wyeWhu8ggvprEYPc", quantity: 1 }],
      successUrl: `${successURL}/?${encodedQueryString}`,
      cancelUrl: cancelURL,
      customerEmail: props.formData.email,
    })
  }
  return (
    <button
      id={props.id}
      disabled={loading || !props.availiable}
      style={
        loading || !props.availiable
          ? { ...buttonStyles, ...buttonDisabledStyles }
          : buttonStyles
      }
      onClick={redirectToCheckout}
    >
      BUY NOW
    </button>
  )
}
export default Checkout
